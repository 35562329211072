#topbanner {
  padding: 40px 0;

  h1,
  h2,
  p {
    color: #fff;
  }

  h1 {
    font-size: 60px;
    font-weight: 600;

    @media (max-width: 767px) {
      font-size: 30px;
    }
  }

  p {
    font-size: 22px;
  }

  .review-widget {
    .header-score {
      font-size: 40px;
    }

    .review-number {
      font-size: 12px;
    }
  }
}